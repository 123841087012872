import React, { useEffect, useState } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

function Home() {
  const [leagues, setLeagues] = useState([
    { id: '2', name: 'Champions League', backgroundImage: 'url("/images/championsleague.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '39', name: 'Premier League', backgroundImage: 'url("/images/england.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '140', name: 'La Liga', backgroundImage: 'url("/images/spain.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '135', name: 'Serie A', backgroundImage: 'url("/images/italy.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '78', name: 'Bundesliga', backgroundImage: 'url("/images/germany.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '61', name: 'Ligue 1', backgroundImage: 'url("/images/france.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '3', name: 'Europa League', backgroundImage: 'url("/images/europaLeague.jpg")', nextGamesDate: 'Finding live games...' }
  ]);

  const [loadingText, setLoadingText] = useState('Finding live games');

  useEffect(() => {
    const loadingInterval = setInterval(() => {
      setLoadingText(prev => {
        if (prev.endsWith('...')) return 'Finding live games';
        return prev + '.';
      });
    }, 500); // Change every 500ms

    return () => clearInterval(loadingInterval); // Clean up on unmount
  }, []);

  useEffect(() => {
    fetchNextGamesDate();
  }, []);

  const fetchNextGamesDate = async () => {
    // Group leagues into pairs and process them two at a time
    const leagueBatches = [];
    
    // Create batches of 2 leagues
    for (let i = 0; i < leagues.length; i += 2) {
      leagueBatches.push(leagues.slice(i, i + 2));
    }
  
    // Process each batch (2 leagues at a time)
    for (const batch of leagueBatches) {
      const promises = batch.map(async (league) => {
        const nextGamesDate = await findNextGamesDate(league.id);
        return { ...league, nextGamesDate };
      });
  
      // Wait for both API calls to complete in this batch
      const updatedLeagues = await Promise.all(promises);
  
      // Update the leagues state for each updated league in the batch
      setLeagues(prevLeagues => 
        prevLeagues.map(league => 
          updatedLeagues.find(updated => updated.id === league.id) || league
        )
      );
  
      // Introduce a small delay before fetching the next batch of 2 leagues
      await new Promise(resolve => setTimeout(resolve, 500)); // 500ms delay between batches
    }
  
    // Implement iframe refresh logic after all batches are processed
    refreshIframes();
  };

  const findNextGamesDate = async (leagueId) => {
    let currentDate = new Date();
    while (true) {
      const formattedDate = currentDate.toISOString().split('T')[0];
      const gamesAvailable = await checkGamesAvailability(leagueId, formattedDate);
      if (gamesAvailable) {
        return formattedDate;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  };

  const checkGamesAvailability = async (leagueId, date) => {
    const response = await fetch(`https://v3.football.api-sports.io/fixtures?date=${date}&league=${leagueId}&season=2024`, {
      method: 'GET',
      headers: {
        'x-rapidapi-host': 'v3.football.api-sports.io',
        'x-rapidapi-key': '4b8a8edb4c304fc2d5dbeda1bbf769d2' // Replace with your actual API key
      }
    });
    const data = await response.json();
    return data.response.length > 0;
  };

  // Function to refresh iframes
  const refreshIframes = () => {
    if (!sessionStorage.getItem('homeIframeRefreshed')) {
      setTimeout(() => {
        document.querySelectorAll('iframe').forEach((iframe) => {
          iframe.src += ''; // Trigger a refresh by updating the src attribute
        });
        sessionStorage.setItem('homeIframeRefreshed', 'true'); // Mark as refreshed in this session
      }, 5000); // Adjust the delay as necessary
    }
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <div>
      <HeroSection />
      <div id="games-container" style={{ width: '100%', overflow: 'hidden', margin: '0', padding: '0' }}>
        {leagues.map((league) => (
          <div key={league.id} style={{ position: 'relative', backgroundImage: league.backgroundImage, backgroundSize: 'cover', backgroundPosition: '50% 50%', marginBottom: '0', border: '3px solid black' }}>
            {league.nextGamesDate === 'Finding live games...' ? (
              <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '5px' }}>
                {loadingText}
              </div>
            ) : league.nextGamesDate !== today ? (
              <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '5px' }}>
                Next game available is on {league.nextGamesDate}
              </div>
            ) : null}
            <iframe
              width="100%"
              src={league.nextGamesDate && league.nextGamesDate !== 'Finding live games...' ? `games.html?league=${league.id}&season=2024&date=${league.nextGamesDate}` : 'about:blank'}
              frameBorder="0"
              style={{ height: '500px', overflow: 'hidden', backgroundSize: 'cover' }}
              title={`league-${league.id}`}
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
